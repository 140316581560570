import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["products", "pagination"];

  connect() {
    this.loading = false; // Empêche le déclenchement multiple de chargements
    this.endOfPagination = false; // Drapeau pour savoir si on a atteint la fin
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll() {
    if (this.loading || this.endOfPagination) return;

    const nextPageLink = this.paginationTarget.querySelector(".next a");
    if (nextPageLink && this.isNearBottom()) {
      this.loadNextPage(nextPageLink.href);
    }
  }

  isNearBottom() {
    return window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
  }

  loadNextPage(url) {
    this.loading = true;

    fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => response.text())
      .then(html => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Ajouter les nouveaux produits
        const newProducts = doc.querySelector('.products-grid').innerHTML;
        this.productsTarget.insertAdjacentHTML('beforeend', newProducts);

        // Mettre à jour ou supprimer la pagination
        const newPagination = doc.getElementById('pagination');

        if (!newPagination.querySelector('.disabled')) {
          this.paginationTarget.innerHTML = newPagination.innerHTML;
        } else {
          this.endOfPagination = true; // Plus de pages à charger
          this.paginationTarget.innerHTML = ""; // Supprime la pagination
          window.removeEventListener('scroll', this.onScroll.bind(this)); // Désactiver le scroll
        }

        this.loading = false;
      })
      .catch(error => {
        console.error('Erreur lors du chargement des produits :', error);
        this.loading = false;
      });
  }
}
