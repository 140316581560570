import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["stats", "bestPublishers", "allPublishers", "employeesTable"];
  static values = { filter: String, data: Array };

  connect() {
    let values = [];

    switch (this.filterValue) {
      case "sale":
        this.dataValue.forEach(product => {
          values.push(product.salePrice);
        });
        let moy1 = this.getMoy(values);
        this.statsTarget.textContent = moy1.toFixed(2) + " €";
        break;

      case "discount":
        this.dataValue.forEach(product => {
          values.push(product.originalPrice - product.salePrice);
        });
        let moy2 = this.getMoy(values);
        this.statsTarget.textContent = moy2.toFixed(2) + " €";
        break;

      case "allPublishers":
        let allPublishersTemp = this.getAllPublishers(this.dataValue);
        if (Object.keys(allPublishersTemp).length > 10) {
          this.allPublishersTarget.classList.add('scrollable-table');
        }
        this.allPublishersTarget.innerHTML = this.displayPublishers(allPublishersTemp);
        break;

      case "bestPublisher":
        let publishers = this.getAllPublishers(this.dataValue);
        let bestPublisher = this.getBestPublisher(publishers);
        this.bestPublishersTarget.innerHTML = this.displayPublishers(bestPublisher, "best");
        break;

      case "employees":
        let employees = this.dataValue;
        this.employeesTableTarget.innerHTML = this.createEmployeesTable(employees);
        break;
    }

  }

  getMoy(values) {
    let total = 0;
    values.forEach(value => {
      total += value;
    });
    return values.length > 0 ? total / values.length : 0;
  }

  formatNumberWithSpaces(number) {
    if(number) return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") ;
  }

  getAllPublishers(data) {
    let publishers = {};
    data.forEach(product => {
      if (!publishers[product.publisher]) {
        publishers[product.publisher] = { count: 0, totalSales: 0 };
      }
      publishers[product.publisher].count++;
      publishers[product.publisher].totalSales += product.salePrice;
    });
    return publishers;
  }

  getBestPublisher(publishers) {
    let bestPublisherName = "X";
    let bestPublisherScore = "0.00";

    if (!publishers || Object.keys(publishers).length === 0) {
      return { name: bestPublisherName, totalSales: bestPublisherScore };
    }

    for (let name in publishers) {
      if (bestPublisherScore === 0 || publishers[name].totalSales > bestPublisherScore) {
        bestPublisherScore = publishers[name].totalSales;
        bestPublisherName = name;
      }
    }

    let result = {
      name: bestPublisherName,
      totalSales: bestPublisherScore
    };

    return result;
  }



  displayPublishers(publishers, onlyOne) {
    if (onlyOne === "best" && publishers) {
      let html = `<p>${publishers.name} - CA : ${this.formatNumberWithSpaces(publishers.totalSales)} €</p>`;
      return html;
    } else {
      let html = `
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Publisher</th>
              <th>Nombre de produits vendus</th>
              <th>Total des ventes (€)</th>
            </tr>
          </thead>
          <tbody>
      `;

      if (publishers) {
        for (const publisher in publishers) {
          html += `
            <tr>
              <td>${publisher}</td>
              <td>${publishers[publisher].count}</td>
              <td>${this.formatNumberWithSpaces(publishers[publisher].totalSales.toFixed(2))} €</td>
            </tr>
          `;
        }
      } else {
        html += `
          <tr>
            <td colspan="3">Aucun éditeur disponible</td>
          </tr>
        `;
      }

      html += `
          </tbody>
        </table>
      `;
      return html;
    }
  }

  createEmployeesTable(employees) {
    let html = `
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
    `;

    employees.forEach(employee => {
      html += `
        <tr>
          <td>${employee.name}</td>
        </tr>
      `;
    });

    html += `
        </tbody>
      </table>
    `;

    return html;
  }

}
